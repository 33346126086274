import React, { Component } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { TextField } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

/*Components*/
import ButtonHeader from '../../../Components/Buttons/ButtonHeader'
import ButtonAction from '../../../Components/Buttons/ButtonAction'
import ButtonSend from '../../../Components/Buttons/ButtonSend'
import ButtonCancel from '../../../Components/Buttons/ButtonCancel'
import Pagination from '../../../Components/Table/Utils/Pagination'
import CsLoader from '../../../Components/Loading/Loader' 

/* Properties */
import Countries from '../../../../Controller/Generic/getCountries'
import {getusers, getroles} from '../../../../Controller/Custom/Management/get'
import PutUsers from '../../../../Controller/Custom/Management/put'
import {postUser} from '../../../../Controller/Custom/Management/post'
import DeleteUsers from '../../../../Controller/Custom/Management/delete'
import Alert from '../../../Components/Alerts/message'

/* Table Header */
import datas from './Data/THeader'

/* Styles */
import 'bootstrap/dist/css/bootstrap.min.css'
import './UserMaintenance.css'

class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state={
            isloading: false,
            modaltype: 'A',
            pagination: {
                currentpage: 1,
                recordperpage: 10
            },
            alerts: {
                open: false,
                severity: '',
                message: ''
            },
            modals:{
                iscreatemodalopen: false,
                isdeletemodalopen: false,
                deletemodal: {
                    title: '',
                    message: ''
                },
            },
            user: props?.user,
            ssdata:{
                id: '',
                value: '',
                name: ''
            },
            gsdata:{
                countries:[],
                roles:[]
            },
            getdata:[],
            createdata:{
                Nombre: '',
                Login: '',
                IdRol: '',
                Email: '',
                IdPais: '',
                FechaInsercion: new Date(),
                UsuarioInsercion: '',
                FechaModificacion: new Date(),
                UsuarioModificacion: ''
            },
            credentialchange: {
                Password: '',
                Username: ''
            },
            selectdata: {
                id: ''
            }    
        };
        this.handleCRChange = this.handleCRChange.bind(this);
        this.handleCRChangeMultiSelect = this.handleCRChangeMultiSelect.bind(this);
      }

    // Event for open modal that it's used for add new user.
    openModal=()=>{
        this.setState({modals: {iscreatemodalopen: !this.state.modals.iscreatemodalopen }})
    }

    // get data period
    getData=async() =>{
        let response = await getusers();
        if(response.status === 200) {
            let datas = []
            response.data.respuesta.Users.forEach((user) => {
                let data = {
                    Username: user.Username,
                    Login: null,
                    Rol: null,
                    Mail: null,
                    Country: null
                }

                user.Attributes.forEach((attribute) => {
                    if(attribute.Name === "email") {
                        data.Login= attribute.Value 
                    }

                    if(attribute.Name === "profile") {
                        data.Rol= attribute.Value 
                    }

                    if(attribute.Name === "locale") {
                        data.Country= attribute.Value 
                    }
                })

                datas.push(data)
            })
            
            this.setState({
                getdata: datas,
                isloading: false
            });
        } else{
            this.setState({
                getdata: [],
                isloading: false
            });
        }
    }

    // select data period
    selectData=(data)=>{
        this.setState({
            selectdata: {
                id: data.Id,
            },
            createdata: {
                Nombre: data.Nombre,
                Login: data.Login,
                Rol: data.Rol,
                Email: data.Login,
                Pais: data.Country,
                FechaInsercion: new Date(),
                UsuarioInsercion: this.state.user?.email,
                FechaModificacion: new Date(),
                UsuarioModificacion: this.state.user?.email
            }
        });

        console.log(this.state.selectdata); 
    }

    // delete data period.
    deleteData=async()=>{
        let response = await DeleteUsers(this.state.createdata.Email);
        if(response.status !== 200) {
            this.setState({alerts: {
                open: true,
                severity: 'error',
                message: response.data.mensaje
             }});
        }else{
            this.setState({alerts: {
                open: true,
                severity: 'success',
                message: response.data.mensaje
             }});
        }
        this.getData();
        this.setState({modals: {isdeletemodalopen: false }});
    }

    // Method that send data user and create
    postData=async()=>{
        if(this.state.createdata !== null) {
            let response = await postUser(this.state.createdata)
            if(response.status !== 200) {
                this.setState({alerts: {
                    open: true,
                    severity: 'error',
                    message: response.data.mensaje
                 }});
            }else{
                this.setState({alerts: {
                    open: true,
                    severity: 'success',
                    message: response.data.mensaje
                 }});
            }

            this.getData();
        }

        this.openModal();
    }

    putData=async()=>{
        let response = await PutUsers(this.state.createdata)
        if(response.codigo !== 200) {
            this.setState({alerts: {
                open: true,
                severity: 'error',
                message: response.data.mensaje
             }});
        }else{
            this.setState({alerts: {
                open: true,
                severity: 'success',
                message: response.mensaje
             }});
        }

        this.getData();
        this.openModal();
    }
    
        // event for selects all values for create data period
    handleCRChangeMultiSelect=async(event, values)=>{
        let countriesId = '';
        values.map(element => {
            if(countriesId === '') {
                countriesId = element.Nombre
            } else {
                countriesId = countriesId + ',' + element.Nombre
            }
        });
        this.setState({
            createdata:{
                ...this.state.createdata,
                Pais: countriesId,
            }
        });

        console.log(this.state.createdata);
    }
    
    // event for selects all values for create data period
    handleCRChange=async(event)=>{
        this.setState({
            createdata:{
                ...this.state.createdata,
                [event.target.name]: event.target.value,
                FechaInsercion: new Date(),
                UsuarioInsercion: this.state.user?.email,
                FechaModificacion: new Date(),
                UsuarioModificacion: this.state.user?.email,
            }
        });

        console.log(this.state.createdata);
    }

    handleCloseAlert() {
        this.setState({alerts: {open: false }});
    }

    // render pagination functionality for data table.
    pageRender(page) {
        this.setState({
            pagination: {
                currentpage: page
            }
        });
    }

    tableRender(currentData) {
        return currentData.length > 0 ?
            this.tableBodyRender(currentData)
            : this.tableDataNotFound();
    }

    tableBodyRender(currentData) {
        return currentData.map((user, index) => {
            return (
                <tr key={user.Id}>
                    <td>{user.Login}</td>
                    <td>{user.Rol}</td>
                    <td>{user.Country}</td>
                    <td>
                        <ButtonAction
                            canEdit={true}
                            canDelete={true}
                            canSeeDetails={false}
                            editRow={() => { this.selectData(user); this.setState({ modaltype: 'U' }); this.openModal(); } }
                            deleteRow={() => { this.selectData(user); this.setState({ modals: { isdeletemodalopen: true } }); } }
                            detailRow={''} />
                    </td>
                </tr>
            );
        });
    }

    tableDataNotFound() {
        return <tr><td colSpan={6} style={{ borderBottom: "0px", textAlign: "left" }}><div style={{ background: 'white' }}>Not have registers.</div></td></tr>;
    }
    
    // Initialize primary components necesary for interact with data.
    componentDidMount=async()=>{
        this.setState({isloading: true});
        let countryresponse = await Countries(this.state.user);
        let roleresponse = await getroles();

        this.setState({
            gsdata: {
                countries: countryresponse.codigo === 200 ? countryresponse.mensaje : [],
                roles: roleresponse.data.respuesta ?? [],
            }
        });

        this.getData();
    }

    render(){
        const indexOfLastRecord = this.state.pagination.currentpage * 10;
        const indexOfFirstRecord = indexOfLastRecord - 10;
        const currentData = this.state.getdata.sort(function(a, b) { return convertToDate(a.UserCreateDate) < convertToDate(b.UserCreateDate)}).slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(this.state.getdata.length / 10);

        return(
            <div>
                <div className='mb-10 txt-righ'>
                    <ButtonHeader event={()=>{this.setState({createdata: null, modaltype: 'A'}); this.openModal()}} name="Add User" />
                </div>
                <div>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                {datas.map((data, index) => {
                                    return(<th key={data.name} className=''>{data.name}</th>)
                                })}
                            </tr>
                        </thead>
                        <tbody>	
                            {this.state.isloading === false
                                ? this.tableRender(currentData) 
                                : <CsLoader />
                            }
                        </tbody>
                    </table>
                    <Pagination pagesCount={nPages} currentPage={this.state.pagination.currentpage} setCurrentPage={this.pageRender.bind(this)}/>
                    <Modal isOpen={this.state.modals.iscreatemodalopen}>
                        <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                            <span style={{float: 'right'}} onClick={()=>this.openModal()}>x</span>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <div className='mb-bt15'>
                                    <div className='mb-10'>
                                        <Box sx={{ maxWidth: '100%' }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    id="Email"
                                                    name="Email"
                                                    variant="outlined"
                                                    label="Mail User"
                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                    defaultValue={this.state.createdata?.Email}
                                                    onChange={this.handleCRChange}
                                                    />
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className='mb-10'>
                                        <Box sx={{ maxWidth: '100%' }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="rolUser">Rol User</InputLabel>
                                                <Select
                                                    labelId="rolUser"
                                                    id="Rol"
                                                    name="Rol"
                                                    label="Rol User"
                                                    defaultValue={this.state.createdata?.Rol}
                                                    onChange={this.handleCRChange}
                                                    >
                                                        {this.state.gsdata.roles.map((country, index) => {
                                                        return(
                                                            <MenuItem key={country.Rol} value={country.Rol}>{country.Rol}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className='mb-10'>
                                        <Box sx={{ maxWidth: '100%' }}>
                                            <FormControl fullWidth>
                                                 {this.state.createdata?.Rol === "Country User" 
                                                    || this.state.createdata?.Rol === "Corporate User"
                                                    || this.state.createdata?.Rol === "Admin"
                                                    || this.state.createdata?.Rol === "Reading User" ?
                                                      <>
                                                      <InputLabel id="countrylabel">Country</InputLabel>
                                                      <Select
                                                          labelId="countrylabel"
                                                          id="Pais"
                                                          name="Pais"
                                                          label="Country"
                                                          value={this.state.createdata?.Pais}
                                                          onChange={this.handleCRChange}
                                                          >
                                                          {this.state.gsdata.countries.sort((a, b) => a.Nombre > b.Nombre).map((country, index)=> {
                                                              return(
                                                                  <MenuItem key={country.Id} value={country.Nombre}>{country.Nombre}</MenuItem>
                                                              )
                                                          })}
                                                      </Select>
                                                  </>                                                
                                                :
                                                    <Autocomplete
                                                        multiple
                                                        id="country"
                                                        options={this.state.gsdata.countries}
                                                        getOptionLabel={(option) => option.Nombre}
                                                        defaultValue={getCountriesByRole(this.state.createdata?.Pais, this.state.gsdata.countries, this.state.modaltype) ?? []}
                                                        onChange={this.handleCRChangeMultiSelect}
                                                        renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Country"
                                                        />
                                                        )}
                                                    />
                                                }
                                            </FormControl>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.modaltype === 'A' ?
                                <ButtonSend event={()=>this.postData()} name="Send Data" />
                                : <ButtonSend event={()=>this.putData()} name="Update" />
                            }
                            <ButtonCancel event={()=>this.openModal()} name="Cancel" />
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modals.isdeletemodalopen}>
                        <ModalBody>
                            Are you sure that you want delete the following User?
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger" onClick={()=>this.deleteData()}>Yes</button>
                            <button className="btn btn-secundary" onClick={()=>this.setState({modals: {isdeletemodalopen: false }})}>No</button>
                        </ModalFooter>
                    </Modal>
                    <Alert open={this.state.alerts.open} severity={this.state.alerts.severity} message={this.state.alerts.message} event={this.handleCloseAlert.bind(this)}/>
                </div>
            </div>
        )

        function convertToDate(data){
            let date = new Date(data);
            return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay();
        }
    }
}

const getCountriesByRole = (country, data, modaltype) => {
    if(modaltype === "U") {
        if(country?.includes(',')) {
            let countries = country.split(',');
            return data.filter((e) => {return countries.includes(e?.Nombre)});
        }else {
            return data.filter((e) => {return e?.Nombre === country})
        }
    }
}

export default UserManagement