import React, { Component } from 'react'
import axios from 'axios'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import Pagination from '../../../Components/Table/Utils/Pagination'
import CsLoader from '../../../Components/Loading/Loader'
import Alert from '../../../Components/Alerts/message'
import CreateSpaceModal from './Modal/CreateSpace'
import UpdateSpaceModal from './Modal/UpdateSpace'
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';

/* Header */
import datas from '../Data/THeader'

/* Data */
import filtercountries from '../../Common/countries';
import {getSpaceByFilter} from '../../../../Controller/Custom/Space/get'
import deleteSpace from '../../../../Controller/Custom/Space/delete'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Space.css'

class Space extends Component {
    constructor(props) {
        super(props);
        this.state={
            isloading: false,
            pagination: {
                currentpage: 1,
                recordperpage: 10
            },
            alerts: {
                open: false,
                severity: '',
                message: ''
            },
            modals:{
                iscreatemodalopen: false,
                isdeletemodalopen: false,
                isopenmodalperiod: false,
                isopenmodalupload:false,
                deletemodal: {
                    title: '',
                    message: ''
                },
            },
            user: props?.user,
            ssdata:{
                data: [],
                id: '',
                value: '',
                name: '',
                country: null,
                modulename: '',
                locations: '',
                code: ''
            },
            gsdata:{
                countries:[],
                months:[],
                periods:[],
                locations:[],
                workcenters:[]
            },
            getdata:[],
            createdata:{
            },
            selectdata: {
                id: ''
            }    
        };
        this.wrapper = React.createRef();
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.handleSLChange = this.handleSLChange.bind(this);
        this.handleSLChangeMulsiSelect = this.handleSLChangeMulsiSelect.bind(this);
    }

    getAllData = async (data) => {
        let spaces = {};

        try {
            const user = this.state.user;
            const response = await axios.get("https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/espaciosAllCog" + "/" + user?.profile + "/" + user?.locale);
            switch (user?.profile) {
                case "Regional User":
                    const countries = user.locale.includes(',')
                        ? user.locale.split(',')
                        : [user.locale];
                    const databyrolecountries = response.data.respuesta.filter(element => countries.includes(element.Pais.Nombre));
                    spaces = {
                        status: response.status,
                        data: { respuesta: databyrolecountries }
                    };
                    break;
                case "Country User":
                case "Reading User":
                    const databyrolecountry = response.data.respuesta.filter(element => user.locale === element.Pais.Nombre);
                    spaces = {
                        status: response.status,
                        data: { respuesta: databyrolecountry }
                    };
                    break;
                case "Admin":
                case "Corporate User":
                    spaces = response;
                    break;
                default:
                    return undefined;
            }
        } catch (error) {
            return error.response;
        }

        this.setState({ getdata: spaces.data.respuesta, isloading: false });
    }
    
    // delete data period.
    deleteData=async()=>{
        let response = await deleteSpace(this.state.selectdata.id, this.state.user);
        if(response.status !== 200){
            this.setState({alerts: {
                open: true,
                severity: 'error',
                message: response.data.mensaje
                }});
        }else{
            this.setState({alerts: {
                open: true,
                severity: 'success',
                message: response.data.mensaje
                }});
        }

        this.cleanComponents()
        this.setState({modals: {isdeletemodalopen: false }});
    }

    // select data period
    selectData=(data)=>{
        this.setState({
            selectdata: {
            id: data.Id,
            }
        });

        console.log(this.state.selectdata); 
    }

    handleCloseAlert() {
        this.setState({alerts: {open: false }});
    }

    // events for selects inputs
    handleSLChange=async(event)=>{
        await this.setState({
            ssdata:{
                ...this.state.ssdata,
                [event.target.name]: event.target.value !== "" ? event.target.value : null,
                }
            });


        let countriesId = '';
        if(this.state.user?.profile == process.env.REACT_APP_ROLE_REG 
            || this.state.user?.profile == process.env.REACT_APP_ROLE_PAS
            || this.state.user?.profile == process.env.REACT_APP_ROLE_BAS) {
            let countryArray = '';

            if( this.state.user?.locale.includes(',')) {
                countryArray = this.state.user?.locale.split(',');
            }else {
                countryArray = this.state.user?.locale;
            }

            let countries = this.state.gsdata.countries.filter((element) => {return countryArray.includes(element.name)});
            countries.map(element => {
                if(countriesId === '') {
                    countriesId = element.id
                } else {
                    countriesId = countriesId + ',' + element.id
                }
            });

            let filter =  {
                ...this.state.ssdata,
                country: countriesId ?? null
            };

            this.getDataByFiler(filter);
        } else {
            let filter =  {
                ...this.state.ssdata,
            };

            this.getDataByFiler(filter);
        }
    }

    // events for selects inputs
    handleSLChangeMulsiSelect=async(event, values)=>{
        let countriesId = '';
        let response = await filtercountries(this.state.user);
        if(values.length === 0 && this.state.user?.profile == process.env.REACT_APP_ROLE_REG
             || this.state.user?.profile == process.env.REACT_APP_ROLE_PAS
             || this.state.user?.profile == process.env.REACT_APP_ROLE_BAS) {
            let countryArray = '';

            if( this.state.user?.locale.includes(',')) {
                countryArray = this.state.user?.locale.split(',');
            }else {
                countryArray = this.state.user?.locale;
            }

            let countries = response.filter((element) => {return countryArray.includes(element.Nombre)});
            countries.map(element => {
                if(countriesId === '') {
                    countriesId = element.Id
                } else {
                    countriesId = countriesId + ',' + element.Id
                }
            });
        } else {
            if(values !== undefined) {
                values.map(element => {
                    if(countriesId === '') {
                        countriesId = element.id
                    } else {
                        countriesId = countriesId + ',' + element.id
                    }
                });
            }
        }

        await this.setState({
            ssdata:{
                ...this.state.ssdata,
                country: countriesId ?? null,
                data: values,
            }
        });

        let filter =  {
            ...this.state.ssdata,
            country: countriesId ?? null
        };

        this.getDataByFiler(filter);
    }
    
    // get data period by filter
    getDataByFiler = async (data) => {
        let newState = {
            getdata: [],
            isloading: false
        };
    
        if (data) {
            const user = this.state.user;
            const response = await getSpaceByFilter(data, user);
            if (response.status === 200) {
                newState.getdata = response.data.respuesta || [];
            }
        }
    
        this.setState(newState);
        this.pageRender(1);
    };
    

    cleanComponents=async()=>{
        this.setState({
            ssdata: {
                data: [],
                country: null,
                modulename: '',
                locations: '',
                code: ''
            }
        });

        let response = await filtercountries(this.state.user);
        if(this.state.user?.profile == process.env.REACT_APP_ROLE_REG 
            || this.state.user?.profile == process.env.REACT_APP_ROLE_PAS
            || this.state.user?.profile == process.env.REACT_APP_ROLE_BAS) {
            let countriesId = '';
            let countryArray = '';

            if( this.state.user?.locale.includes(',')) {
                countryArray = this.state.user?.locale.split(',');
            }else {
                countryArray = this.state.user?.locale;
            }

            let countries = response.filter((element) => {return countryArray.includes(element.Nombre)});
            countries.map(element => {
                if(countriesId === '') {
                    countriesId = element.Id
                } else {
                    countriesId = countriesId + ',' + element.Id
                }
            });

            this.getDataByFiler({
                locations: null,
                modulename: null,
                code: null,
                country: countriesId
            });
        } else if (this.state.user?.profile == process.env.REACT_APP_ROLE_COR || this.state.user?.profile == process.env.REACT_APP_ROLE_ADM) {
            this.getAllData(null);
        } else {
            this.setState({
                getdata: [],
                isloading: false
            });
        }
    }

    // render pagination functionality for data table.
    pageRender(page) {
        this.setState({
            pagination: {
                currentpage: page
            }
        });
    }

    updateUser=(data)=> {
        this.setState({user: data});
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.user !== this.props.user) {  // Comparando prevProps con this.props
            this.updateUser(prevProps.user);
            this.renderInitialData(prevProps.user, true);
        }
    }

    // initialice status components
    componentDidMount=async()=>{
        await this.renderInitialData(this.state.user, false)
    }

    async renderInitialData(user, isrender) {
        this.setState({
            isloading: true
        })
        let response = await filtercountries(user);
        if (user?.profile == process.env.REACT_APP_ROLE_REG
             || user?.profile == process.env.REACT_APP_ROLE_PAS
             || user?.profile == process.env.REACT_APP_ROLE_BAS) {
            let countriesId = ''
            let countryArray = ''

            if (user?.locale.includes(',')) {
                countryArray = user?.locale.split(',')
            } else {
                countryArray = user?.locale
            }

            let countries = response.filter((element) => { return countryArray.includes(element.Nombre) })
            countries.map(element => {
                if (countriesId === '') {
                    countriesId = element.Id
                } else {
                    countriesId = countriesId + ',' + element.Id
                }
            })

            this.getDataByFiler({
                locations: null,
                modulename: null,
                code: null,
                country: countriesId
            })
        } else if (user?.profile == process.env.REACT_APP_ROLE_COR || user?.profile == process.env.REACT_APP_ROLE_ADM) {
            this.getAllData(null);
        } else {
            if(!isrender) {
                this.setState({
                    getdata: [],
                    isloading: false
                });
            }
        }

        let orderedcountries = []
        response.map(element => {
            orderedcountries.push({
                id: element.Id,
                name: element.Nombre
            })
        })
        this.setState({
            gsdata: {
                countries: orderedcountries ?? [],
            }
        })

        console.log(orderedcountries)
    }

    tableRender(currentData) {
        return currentData.length > 0
            ? this.tableBodyDataRender(currentData)
            : this.tableDataNotFound()
    }

    tableBodyDataRender(currentData) {
        return currentData.map((space, index) => {
            return (
                <tr key={space.id}>
                    <td>{space.Nombre}</td>
                    <td>{space.Codigo}</td>
                    <td>{space.Pais.Nombre}</td>
                    <td>{space.Ubicacion}</td>
                    <td>
                        <UpdateSpaceModal isOpen={this.state.modals.iscreatemodalopen}
                            dataRender={this.getAllData.bind(this)}
                            id={this.state.ssdata.id}
                            user={this.state.user}
                            profile={this.state.user?.profile}
                            data={space}
                            deleteRow={() => { this.selectData(space); this.setState({ modals: { isdeletemodalopen: true } }) } } />
                    </td>
                </tr>
            )
        })
    }

    tableDataNotFound() {
        return <tr><td colSpan={6} style={{ borderBottom: "0px", textAlign: "left" }}><div style={{ background: 'white' }}>Not have registers.</div></td></tr>
    }

    render(){
        const indexOfLastRecord = this.state.pagination.currentpage * 12;
        const indexOfFirstRecord = indexOfLastRecord - 12;
        const currentData = this.state.getdata.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(this.state.getdata.length / 12);

        return(
        <div ref={this.wrapper}>
                <div className='mb-15'>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Box>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        multiple
                                        id="country"
                                        options={this.state.gsdata.countries ?? []}
                                        getOptionLabel={option => (option ? option.name : "")}
                                        Country
                                        disabled={this.state.user?.profile === "Country User"
                                            || this.state.user?.profile === "Reading User"}
                                        value={this.state.ssdata?.data}
                                        onChange={this.handleSLChangeMulsiSelect}
                                        getOptionDisabled={(option) =>
                                            getCountriesByRole(this.state.user, option)
                                        }
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            placeholder="Countries"
                                        />
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="modulename"
                                        name="modulename"
                                        value={this.state.ssdata.modulename === null ? '' : this.state.ssdata.modulename}
                                        label="Module Name"
                                        onChange={this.handleSLChange}
                                        />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="locations"
                                        name="locations"
                                        value={this.state.ssdata.locations === null ? '' : this.state.ssdata.locations}
                                        label="Location"
                                        onChange={this.handleSLChange}
                                        />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="code"
                                        name="code"
                                        value={this.state.ssdata.code === null ? '' : this.state.ssdata.code}
                                        label="Module Code"
                                        onChange={this.handleSLChange}
                                        />
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            <div className='mb-10 txt-righ'>
                <CreateSpaceModal isOpen={this.state.modals.iscreatemodalopen}
                    dataRender={this.getAllData.bind(this)}
                    id={this.state.ssdata.id}
                    user={this.state.user}
                    cleanComponents={this.cleanComponents.bind(this)}/>
            </div>
            <div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            {datas.map((data) => {
                                return(<th key={data.name} className={data.style}>{data.name}</th>)
                            })}
                        </tr>
                    </thead>
                    <tbody>	
                        {this.state.isloading === false
                            ? this.tableRender(currentData)
                            : <CsLoader />
                        }
                    </tbody>
                </table>
                <Pagination pagesCount={nPages} currentPage={this.state.pagination.currentpage} setCurrentPage={this.pageRender.bind(this)}/>
                <Modal isOpen={this.state.modals.isdeletemodalopen}>
                    <ModalBody>
                        Are you sure that you want delete the following space?
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-danger" onClick={e => this.deleteData()}>Yes</button>
                        <button className="btn btn-secundary" onClick={e =>this.setState({modals: {isdeletemodalopen: false }})}>No</button>
                    </ModalFooter>
                </Modal>         
                <Alert open={this.state.alerts.open} severity={this.state.alerts.severity} message={this.state.alerts.message} event={this.handleCloseAlert.bind(this)}/>
            </div>
        </div>
        );
    }
}

const getCountriesByRole = (data, option) => {
    if(data?.profile == process.env.REACT_APP_ROLE_REG
        || data?.profile == process.env.REACT_APP_ROLE_PAS
        || data?.profile == process.env.REACT_APP_ROLE_BAS) {
        console.log(data);
        if(data.locale.includes(',')) {
            let countries = data.locale.split(',');
            console.log(countries);
            return !countries.includes(option.name);
        }else {
            return option.name !== data.locale;
        }
    }
}

export default Space