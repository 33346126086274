import React, { Component } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';

/* Get Data */
import { getMeasures, getFilterMeasures, getPeriod, getWorkCenters, getMeasureDetails } from '../Business/Events/measuresget';
import { delMeasures } from '../Business/Events/measuresdelete';
import filtercountries from '../../Common/countries';
import {uploadmeasuremasive} from '../../../../Controller/Generic/uploadfile'

/* Custom Components */
import ButtonSend from '../../../Components/Buttons/ButtonSend'
import ButtonCancel from '../../../Components/Buttons/ButtonCancel'
import Pagination from '../../../Components/Table/Utils/Pagination'
import Alert from '../../../Components/Alerts/message'
import CsLoader from '../../../Components/Loading/Loader'
import SectionMeasures from './Modal/SectionMeasure'
import SectionUpload from './Modal/SectionUpload'
import MeasureDetails from './Details/MeasureDetails'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Measures.css'

class Information extends Component {
    constructor(props) {
        super(props);
        this.state={
            isloading: false,
            isloadingmeasure: false,
            idPeriodo: 0,
            user: props?.user,
            measurecheck: false,
            pagination: {
                currentpage: 1,
                recordperpage: 6
            },
            alerts: {
                open: false,
                severity: '',
                message: ''
            },
            modals:{
                iscreatemodalopen: false,
                isdeletemodalopen: false,
                isopenmodalupload:false,
                deletemodal: {
                    title: '',
                    message: ''
                },
            },
            isfilepicked: false,
            fileupload: {
                file: '',
                base64: '',
            },
            ssdata:{
                data: [],
                country: null,
                months: null,
                periods: null,
                locations: null,
                workcenters: null,
                ids: null,
                id:''
            },
            gsdata:{
                countries:[],
                months:[],
                periods:[],
                locations:[],
                workcenters:[]
            },
            getdata:[],
            getdataresume: '',
            createdata:{
                medidasAuto:[],
                medidasManual:[]
            },
            loadingUpload: false,
            disabledUpload: false,
            ssresume:{
                SuperficieConstruida: '',
                PuestosTeleoperador: '',
                PuestosCoordinadorSupervisor: '',
                PuestosEstructura: '',
                AsientosSalaReuniones: '',
                AsientosTraining:'',
                AsientosOficinaComedorDescanso:'',
                AsientosOtros:'',
                PlazasParking: '',
                AlquilerMensual:'',
                Impuestos: '',
                CosteComunitario:'',
                Mantenimiento: '',
                Limpieza: '',
                Seguridad: '',
                CentroMedico: '',
                Electricidad: '',
                Agua:'',
                Comunicaciones: '',
                MaterialOficina: '',
                TransportesTraslados: '',
                PersonalTecnico: '',
                RedElectrica: '',
                Otros: ''
            },
            selectdata: {
                idespacio: '',
                idperiodo: '',
            } 
        };

        this.wrapper = React.createRef();
        this.handleSLChange = this.handleSLChange.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.handleRSChange = this.handleRSChange.bind(this);
        this.handleSLChangeMulsiSelect = this.handleSLChangeMulsiSelect.bind(this);
        this.pageRender = this.pageRender.bind(this);    
    }

    // select data period
    selectData=(data)=>{
        if(data !== null && data !== '') {
            this.setState({
                selectdata: {
                    idespacio: data.idEspacio,
                    idperiodo: data.IdPeriodoPais,
                }
              });

              console.log(this.state.selectdata);
        }
    }

    // delete data period.
    deleteData=async()=>{
        let formulary = {
            idespacio: this.state.selectdata.idespacio,
            idperiodo: this.state.selectdata.idperiodo,
        };
        let response = await delMeasures(this.state.user, "medidasDeleteCog", formulary);
        this.setState({alerts: {
            open: response.open,
            severity: response.severity,
            message: response.message
        }});
        this.getAllData(null);
        this.setState({modals: {isdeletemodalopen: false }});
    }

    // get data period by filter
    getAllData=async(data) =>{
        //function method for get measures
        let response = await getMeasures("medidasAllCog", this.state.user, null);
        this.setState({
            getdata: response,
            isloading: false
        });
    }

    // get data period by filter
    getDataByFiler=async(data) =>{
        //function method for get measures
        let response = await getFilterMeasures("medidasFiltroCog", this.state.user, data);
        this.setState({
            getdata: response,
            isloading: false
        });

        this.pageRender(1);
    }
    
    // Method for get data measures section.
    getViewDataMeasures=(data)=> {
        this.getDataMeasures(data).then(() => {
            this.handleRSChange(data)
        }); 
    }

    // get data period by filter
    getDataMeasures = async (data) => {
        getMeasureDetails("medidasPeriodoEspacioCog", this.state.user, data)
            .then(data => {
                if (data?.data?.respuesta) {
                    const { medidasAuto, medidasManual } = data.data.respuesta;
                    this.setState({
                        ssresume: {
                            SuperficieConstruida: medidasAuto?.SuperficieConstruida,
                            PuestosTeleoperador: medidasAuto?.PuestosTeleoperador,
                            PuestosCoordinadorSupervisor: medidasAuto?.PuestosCoordinadorSupervisor,
                            PuestosEstructura: medidasAuto?.PuestosEstructura,
                            AsientosSalaReuniones: medidasAuto?.AsientosSalaReuniones,
                            AsientosTraining: medidasAuto?.AsientosTraining,
                            AsientosOficinaComedorDescanso: medidasAuto?.AsientosOficinaComedorDescanso,
                            AsientosOtros: medidasAuto?.AsientosOtros,
                            PlazasParking: medidasAuto?.PlazasParking,
                            AlquilerMensual: medidasAuto?.AlquilerMensual,
                            Impuestos:  medidasAuto?.Impuestos,
                            ImpuestoActividad: medidasManual?.ImpuestoActividad,
                            ImpuestoPropiedad: medidasManual?.ImpuestoPropiedad,
                            CosteComunitario: medidasAuto?.CosteComunitario,
                            Mantenimiento: medidasManual?.Mantenimiento,
                            Limpieza: medidasManual?.Limpieza,
                            Seguridad: medidasManual?.Seguridad,
                            CentroMedico: medidasManual?.CentroMedico,
                            Electricidad: medidasManual?.Electricidad,
                            Agua: medidasManual?.Agua,
                            Comunicaciones: medidasManual?.Comunicaciones,
                            MaterialOficina: medidasManual?.MaterialOficina,
                            TransportesTraslados:  medidasManual?.TransportesTraslados,
                            PersonalTecnico: medidasManual?.PersonalTecnico,
                            RedElectrica: medidasManual?.RedElectrica,
                            Otros: medidasManual?.Otros
                        },
                        isloading: false
                    });
                }
            });
    }

    // Method for render general data next of events.
    dataRender(data) {
        if(data !== null && data !== '') {
            this.setState({
                ssdata: {
                    id: data
                }
            });

            this.getAllData(null);
        }
    }

    // events for selects inputs
    handleSLChangeMulsiSelect=async(event, values)=>{
        let countriesId = '';
        let response = await filtercountries(this.state.user);
        if(values.length === 0 && 
            this.state.user?.profile == process.env.REACT_APP_ROLE_REG
             || this.state.user?.profile == process.env.REACT_APP_ROLE_PAS
             || this.state.user?.profile == process.env.REACT_APP_ROLE_BAS) {
            let countryArray = '';

            if( this.state.user?.locale.includes(',')) {
                countryArray = this.state.user?.locale.split(',');
            }else {
                countryArray = this.state.user?.locale;
            }

            let countries = response.filter((element) => {return countryArray.includes(element.Nombre)});
            countries.map(element => {
                if(countriesId === '') {
                    countriesId = element.Id
                } else {
                    countriesId = countriesId + ',' + element.Id
                }
            });
        } else {
            if(values !== undefined && values.length != 0) {
                values.map(element => {
                    if(countriesId === '') {
                        countriesId = element.Id
                    } else {
                        countriesId = countriesId + ',' + element.Id
                    }
                });
            }
        }

        this.setState({
            ssdata:{
                ...this.state.ssdata,
                country: values,
                ids: countriesId ?? null,
                data: values
            }
        });

        let filter = {
            locations: this.state.ssdata.locations == "" ? null : this.state.ssdata.locations,
            workcenters: this.state.ssdata.workcenters,
            periods:  this.state.ssdata.periods,
            country:  this.state.ssdata.country,
            ids: countriesId === '' ? null : countriesId
        };

        this.getDataByFiler(filter);
    }

    handleSLChange=async (e)=>{
        await this.setState({
            ssdata:{
                ...this.state.ssdata,
                [e.target.name]: e.target.value !== "" ? e.target.value : null
              }
          });

        let filter = {
            locations: this.state.ssdata.locations == "" ? null : this.state.ssdata.locations,
            workcenters: this.state.ssdata.workcenters,
            periods:  this.state.ssdata.periods,
            country:  this.state.ssdata.country,
            ids: this.state.ssdata.ids == undefined ? null : this.state.ssdata.ids
        };

        this.getDataByFiler(filter);
    }

    handleCloseAlert() {
        this.setState({alerts: {open: false }});
    }
    
    handleRSChange = (data) => {
        if(data !== null && data !== '') {
            let newdata = parseInt(data.Id);

            //si el periodo es 0, 
            if(this.state.idPeriodo === 0) {
                this.setState({
                    measurecheck: this.state.measurecheck ? false : true,
                    idPeriodo: parseInt(data.Id),
                    isloadingmeasure: false
                })
            } else if(newdata !== this.state.idPeriodo ) {
                this.setState({
                    isloadingmeasure: false,
                    idPeriodo: parseInt(data.Id),
                })
            } else if(newdata === this.state.idPeriodo ) {
                this.setState({
                    measurecheck: this.state.measurecheck ? false : true,
                    idPeriodo: 0,
                    isloadingmeasure: false
                })
            } else{
                this.setState({
                    measurecheck: this.state.measurecheck ? false : true,
                    idPeriodo: 0,
                    isloadingmeasure: false
                })
            }

            console.log(this.state.measurecheck);
        }
    };
    
    getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();
    
            // Convert the file to base64 text
            reader.readAsDataURL(file);
    
            // on reader load somthing...
            reader.onload = () => {
              // Make a fileInfo Object
              console.log("Called", reader);
              baseURL = reader.result;
              console.log(baseURL);
              resolve(baseURL);
            };
            console.log(fileInfo);
        });
    }
      
    changeHandler = async e => {
        this.setState({isfilepicked: true});
        let file = e.target.files[0];
    
        this.getBase64(file)
          .then(result => {
            file["base64"] = result;
            console.log("File Is", file);
            this.setState({
                fileupload: {
                    file: e.target.files[0],
                    base64: result
                }
            });
          })
          .catch(err => {
            console.log(err);
        }); 
    };

    handleSubmission = async() => {
        this.setState({loadingUpload: true});
        this.setState({disabledUpload: true});

        let formulary = {
            plantilla: "medida",
            nombreFichero: this.state.fileupload.file.name,
            ficheroCarga: this.state.fileupload.base64.split(',')[1]
        }
    
        let response = await uploadmeasuremasive(formulary);
        
        if(response.status === 200) {
             this.setState({alerts: {
                    open: true,
                    severity: 'success',
                    message: 'File upload successfully.'
                    }});
        } else {
                 this.setState({alerts: {
                    open: true,
                    severity: 'error',
                    message: 'Error while upload file.'
                    }});
        }
    
        this.setState({loadingUpload: false});
        this.setState({disabledUpload: false});
        this.openupload();
    };
    
    cleanComponents=async()=>{
        this.setState({
            ssdata: {
                data: [],
                country: null,
                periods: null,
                locations: '',
                workcenters: null
            }
        });

        let response = await filtercountries(this.state.user);
        if(this.state.user?.profile == process.env.REACT_APP_ROLE_REG
                || this.state.user?.profile == process.env.REACT_APP_ROLE_PAS
                || this.state.user?.profile == process.env.REACT_APP_ROLE_BAS) {
            let countriesId = '';
            let countryArray = '';

            if(this.state.user?.locale.includes(',')) {
                countryArray = this.state.user?.locale.split(',');
            }else {
                countryArray = this.state.user?.locale;
            }

            let countries = response.filter((element) => {return countryArray.includes(element.Nombre)});
            countries.map(element => {
                if(countriesId === '') {
                    countriesId = element.Id
                } else {
                    countriesId = countriesId + ',' + element.Id
                }
            });

            let filter = {
                locations: null,
                workcenters: null,
                periods: null,
                country: null,
                ids: countriesId
            };

            this.getDataByFiler(filter);
        } else if (this.state.user?.profile == process.env.REACT_APP_ROLE_COR || this.state.user?.profile == process.env.REACT_APP_ROLE_ADM) {
            this.getAllData(null);
        } else {
            this.setState({
                getdata: [],
                isloading: false
            });
        }
    }

    openupload=()=>{
        this.setState({modals: {isopenmodalupload: !this.state.modals.isopenmodalupload }})
    }

    pageRender(page) {
        if(page !== null && page !== '') {
            this.setState({
                pagination: {
                    currentpage: page
                }
            });
        }
    }

    updateUser=(data)=> {
        this.setState({user: data});
    }

    renderInitialData = async(user, isrender) => {
        this.setState({ isloading: true });

        let presponse = await getPeriod("periodosCog", null, null);
        let wresponse = await getWorkCenters("plataformasCog", user, null);
        let cresponse = await filtercountries(user);

        if (user?.profile == process.env.REACT_APP_ROLE_REG
                || user?.profile == process.env.REACT_APP_ROLE_PAS
                || user?.profile == process.env.REACT_APP_ROLE_BAS) {
            let countriesId = '';
            let countryArray = '';

            if (user?.locale.includes(',')) {
                countryArray = user?.locale.split(',');
            } else {
                countryArray = user?.locale;
            }

            let countries = cresponse.filter((element) => { return countryArray.includes(element.Nombre); });
            countries.map(element => {
                if (countriesId === '') {
                    countriesId = element.Id;
                } else {
                    countriesId = countriesId + ',' + element.Id;
                }
            });

            let filter = {
                locations: null,
                workcenters: null,
                periods: null,
                country: null,
                ids: countriesId != "" ? countriesId : null
            };

            this.getDataByFiler(filter);
        } else if (user?.profile == process.env.REACT_APP_ROLE_COR || user?.profile == process.env.REACT_APP_ROLE_ADM) {
            this.getAllData(null);
        } else {
            if(!isrender) {
                this.setState({
                    getdata: [],
                    isloading: false
                });
            }
        }

        this.setState({ gsdata: {
                countries: cresponse,
                periods: presponse,
                workcenters: wresponse
            },
        });
    }

    // Initialize primary components necesary for interact with data.
    componentDidMount=async()=>{
        await this.renderInitialData(this.state.user, false);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.user != this.state.user) {
            setTimeout(() => {
                this.updateUser(prevProps.user);
                this.renderInitialData(prevProps.user, true);
            }, 1000);
        }
    }

    render(){
        const indexOfLastRecord = this.state.pagination.currentpage * 6;
        const indexOfFirstRecord = indexOfLastRecord - 6;
        const currentData = this.state.getdata.sort(function(a, b) { return a.Periodo < b.Periodo}).slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(this.state.getdata.length / 6);

        return(
            <div ref={this.wrapper}>
                <div className='mb-15'>
                    <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Box> 
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            id="country"
                                            options={this.state.gsdata.countries ?? []}
                                            getOptionLabel={option => (option ? option.Nombre : "")}
                                            Country
                                            disabled={this.state.user?.profile === "Country User"
                                                || this.state.user?.profile === "Reading User"}
                                            value={this.state.ssdata?.data}
                                            onChange={this.handleSLChangeMulsiSelect}
                                            getOptionDisabled={(option) =>
                                                getCountriesByRole(this.state.user, option)
                                            }
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                placeholder="Countries"
                                            />
                                            )}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box>
                                    <FormControl fullWidth>
                                        <InputLabel id="periodLabel">Period</InputLabel>
                                        <Select
                                            labelId="periodLabel"
                                            id="periods"
                                            name="periods"
                                            value={this.state.ssdata.periods === null ? '' : this.state.ssdata.periods}
                                            label="periods"
                                            onChange={this.handleSLChange}>
                                            {this.state.gsdata.periods.map((data, index)=> {
                                                return(
                                                    <MenuItem key={data.FechaPeriodo} value={data.FechaPeriodo}>{periodMonth(data.FechaPeriodo)}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="locations"
                                            name="locations"
                                            label="Location"
                                            value={this.state.ssdata.locations === null ? '' : this.state.ssdata.locations}
                                            onChange={this.handleSLChange}
                                            />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box>
                                    <FormControl fullWidth>
                                        <InputLabel id="workcenterLabel">Associated work center</InputLabel>
                                        <Select
                                            labelId="workcenterLabel"
                                            id="workcenters"
                                            name="workcenters"
                                            value={this.state.ssdata.workcenters === null || this.state.ssdata.workcenters === '' ? '' : this.state.ssdata.workcenters}
                                            label="workcenters"
                                            onChange={this.handleSLChange}
                                            >
                                            {this.state.gsdata.workcenters.map((data, index) => {
                                                return(
                                                    <MenuItem key={data.Id} value={data.Id}>{data.Nombre}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                </div>
                <div className='mb-10 txt-righ'>
                    <SectionUpload cleanComponents={this.cleanComponents.bind(this)} user={this.state.user} />
                </div>
                <div>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th className='th-month'>Module Name</th>
                                <th className='th-month'>Associate Work Center</th>
                                <th className='th-month'>Location</th>
                                <th className='th-month'>Country</th>
                                <th>Period</th>
                                <th className='th-action'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.isloading === false
                                ? (currentData.length > 0 ?
                                    currentData.map((information, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{information.ModuleName}</td>
                                                <td>{information.AssociatedWorkCenter}</td>
                                                <td>{information.Location}</td>
                                                <td>{information.Country}</td>
                                                <td>{periodMonth(information.Periodo)}</td>
                                                <td>
                                                    <SectionMeasures isOpen={this.state.modals.iscreatemodalopen}
                                                         dataRender={this.dataRender.bind(this)}
                                                         id={this.state.ssdata.id}
                                                         user={this.state.user}
                                                         data={information}
                                                         isvisible={this.state.user?.profile === 'Admin'}
                                                         deleteRow={()=>{this.selectData(information); this.setState({modals: {isdeletemodalopen: true }})}}
                                                         detailMeasures={()=>{this.getViewDataMeasures(information);}}
                                                         detailResume={()=>{this.getDataMeasures(information);}}/>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : <tr><td colSpan={6}  style={{borderBottom: "0px", textAlign: "left" }}><div style={{background:'white'}}>Not have registers.</div></td></tr>)
                                : <CsLoader />
                            }
                        </tbody>
                    </table>
                    <Pagination pagesCount={nPages} currentPage={this.state.pagination.currentpage} setCurrentPage={this.pageRender.bind(this)}/>
                    <div>
                        <MeasureDetails isChecked={this.state.measurecheck} data={this.state.ssresume} user={this.state.user}/>
                    </div>
                    <Modal isOpen={this.state.modals.isdeletemodalopen}>
                        <ModalBody>
                            Are you sure that you want delete the following information?
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger" onClick={()=>this.deleteData()}>Yes</button>
                            <button className="btn btn-secundary" onClick={()=>this.setState({modals: {isdeletemodalopen: false }})}>No</button>
                        </ModalFooter>
                    </Modal>
                    {/* Modal upload */}
                    <Modal isOpen={this.state.modals.isopenmodalupload}>
                        <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                            <span style={{float: 'right'}} onClick={()=> this.openupload()}>x</span>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <label for="file" class="drop-container">
                                    <span class="drop-title">Drop files here</span>
                                    or
                                    <input type="file" id="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" required onChange={()=>this.changeHandler()}/>
                                </label>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonSend event={() => this.handleSubmission()} disabled={this.state.disabledUpload} loading={this.state.loadingUpload} name="Upload" />
                            <ButtonCancel event={() => this.openupload()} name="Cancel" />
                        </ModalFooter>
                    </Modal>
                    <Alert open={this.state.alerts.open} severity={this.state.alerts.severity} message={this.state.alerts.message} event={this.handleCloseAlert.bind(this)}/>
                </div>
            </div>
        )

        function periodMonth(date) {
            let dateperiod = new Date(date);
            return dateperiod.toLocaleString('en-US', { month: 'long' }) + " " + dateperiod.getFullYear()
        }
    }
}

const getCountriesByRole = (data, option) => {
    if(data?.profile == process.env.REACT_APP_ROLE_REG
        || data?.profile == process.env.REACT_APP_ROLE_PAS
        || data?.profile == process.env.REACT_APP_ROLE_BAS) {
        console.log(data);
        if(data.locale.includes(',')) {
            let countries = data.locale.split(',');
            console.log(countries);
            return !countries.includes(option.Nombre);
        }else {
            return option.Nombre !== data.locale;
        }
    }
}

export default Information