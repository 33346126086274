export const REACT_APP_AWS_API='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/';
export const REACT_APP_AWS_API_GET_COUNTRIES='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/paisesAllCog';
export const REACT_APP_AWS_API_GET_CURRENCIES='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/monedasCog';
export const REACT_APP_AWS_API_MEASURE_GET_PAISES='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/medidasCog';
export const REACT_APP_AWS_API_MEASURE_PUT='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/medidasCog/';
/*Espacios*/
export const REACT_APP_AWS_API_SPACE_GET_ALL='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/espaciosAllCog';
export const REACT_APP_AWS_API_SPACE_GET_BY_ID='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/espaciosAllCog/';
export const REACT_APP_AWS_API_SPACE_GET_BY_FILTER='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/desplegablesEspacioValCog/';
export const REACT_APP_AWS_API_SPACE_POST='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/espaciosCreateCog/';
export const REACT_APP_AWS_API_SPACE_PUT='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/espaciosUpdatedCog/';
export const REACT_APP_AWS_API_SPACE_DELETE='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/espaciosDeleteCog/';
export const REACT_APP_AWS_API_SPACE_FILTER='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/espaciosFiltradoCog/';
export const REACT_APP_AWS_API_SPACE_STATUS='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/estadoCargaEspacioCog';
/*Mantenimiento*/
export const REACT_APP_AWS_API_PLATFORM_POST='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/createSolicitudPlataformaCog/';
export const REACT_APP_AWS_API_MAINTENANCE_GET_RENTAL_REVIEW='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/indicesCog';
export const REACT_APP_AWS_API_MAINTENANCE_GET_ENERGETIC_CERTIFICATE='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/certificadosEnergeticosCog';
export const REACT_APP_AWS_API_MAINTENANCE_GET_EXENCTION_CERTIFICATE='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/certificadosExencionCog';
export const REACT_APP_AWS_API_MAINTENANCE_DELETE_RENTAL_REVIEW='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/indicesCog/';
export const REACT_APP_AWS_API_MAINTENANCE_DELETE_ENERGETIC_CERTIFICATE='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/certificadosEnergeticosCog/';
export const REACT_APP_AWS_API_MAINTENANCE_DELETE_EXENCTION_CERTIFICATE='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/certificadosExencionCog/';
export const REACT_APP_AWS_API_MAINTENANCE_POST_RENTAL_REVIEW='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/indicesCog/';
export const REACT_APP_AWS_API_MAINTENANCE_POST_ENERGETIC_CERTIFICATE='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/certificadosEnergeticosCog/';
export const REACT_APP_AWS_API_MAINTENANCE_POST_EXENCTION_CERTIFICATE='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/certificadosExencionCog/';
export const REACT_APP_AWS_API_MAINTENANCE_PUT_RENTAL_REVIEW='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/indicesCog/';
export const REACT_APP_AWS_API_MAINTENANCE_PUT_ENERGETIC_CERTIFICATE='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/certificadosEnergeticosCog/';
export const REACT_APP_AWS_API_MAINTENANCE_PUT_EXENCTION_CERTIFICATE='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/certificadosExencionCog/';
export const REACT_APP_AWS_API_MANAGEMENT_GET_ALL='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/usuariosCog';
export const REACT_APP_AWS_API_MANAGEMENT_GET_ROLES='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/rolesCog';
export const REACT_APP_AWS_API_MANAGEMENT_POST='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/usuariosCog';
export const REACT_APP_AWS_API_MANAGEMENT_PUT='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/usuariosCog/';
export const REACT_APP_AWS_API_MANAGEMENT_DELETE='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/usuariosCog/';
export const REACT_APP_AWS_API_MANAGEMENT_POST_PASSWORD='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/setPassword/';
export const REACT_APP_AWS_API_SPACE_UPLOAD='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/cargaMasivaEspacioCog';
export const REACT_APP_AWS_API_MEASURES_UPLOAD='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/cargaMasivaMedidaCog';
export const REACT_APP_AWS_API_MEASURE_STATUS='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/estadoCargaMedidaCog';
export const REACT_APP_AWS_API_REPORTS='https://fm3ifp3fgd.execute-api.eu-west-1.amazonaws.com/prd/informesCog';